<template>
  <div>

    <div class="location-settings-content locations-page">
      <div class="settings-card p-0">
        <div class="card-body p-0">
          <div v-for="location in displayedLocations" @click="selectLocation(location)"

               class="cc-card-box pb-1 pt-1  mb-0 border-bottom-0 cursor-pointer hover-bg-light-primary"
               :class="location.selected?'bg-light-success':''">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mt-1">
                <div v-if="!locationsLoading" class="initials">
                  JG

                </div>
                <skeleton v-else loading="true" class="initials" circle/>

                <div class="ml-1">
                  <div class="card-abbr">
                    <!-- First letter upercase -->
                    <span v-if="!locationsLoading">{{ location.name }}</span>
                    <skeleton v-else loading="true" class="d-block" style="width:135px"/>
                  </div>
                  <div class="card-exp">
                    <span v-if="!locationsLoading">Blah blah</span>
                    <skeleton v-else loading="true" class="d-block" style="width:155px"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center"
            >

              <div class="location-check-wrapper">
                <svg v-if="location.selected" xmlns="http://www.w3.org/2000/svg" fill="#4bde80"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                >
                  <path
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20"
                     viewBox="0 0 24 24"
                     stroke="#d1d5dc" stroke-width="2">
                  <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="locations-bottom-bar">
        <div class="container d-flex justify-content-between align-items-center h-100">
          <div>
            <input
                v-model="locationsSearch"
                type="text" class="form-control min-w-240px" placeholder="Search locations...">
          </div>
          <div class="d-flex ">
            <b-button
                @click.prevent="selectedAllLocations"
                class="submit-form-btn mr-1"
                variant="flat-primary"
            >
              {{ allLocationsAreSelected ? 'Deselect All' : 'Select All' }}
            </b-button>
            <b-button
                @click.prevent="setupLocations"
                class="submit-form-btn"
                :disabled="loading"
                variant="primary">
              <span v-if="loading">
                Saving...
              </span>
              <span v-else>
              Save Changes
              </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton, BAlert, BFormCheckbox} from "bootstrap-vue";
import {Skeleton} from "vue-loading-skeleton";
import LocationsService from "@/services/LocationsService";
import toastsMixin from "@/mixins/toastsMixin";

export default {
  name: 'BillingSettings',
  components: {
    BButton,
    BAlert,
    BFormCheckbox,
    Skeleton

  },
  data() {
    return {
      loading: false,
      locationsLoading: true,
      // Fill with 10 empty objects
      locations: [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
      ],
      displayedLocations: [],
      locationsSearch: '',
      time: 0
    }
  },
  mixins: [toastsMixin],
  computed: {
    selectedLocations() {
      return this.locations.filter(location => location.selected);
    },
    allLocationsAreSelected() {
      return this.locations.every(location => location.selected);
    }
  },
  watch: {
    locationsSearch() {
      this.displayedLocations = this.locations.filter(location => {
        return location.name.toLowerCase().includes(this.locationsSearch.toLowerCase());
      });
    }
  },
  methods: {
    async fetchLocations() {

      this.locationsLoading = true;
      const {data: {locations}} = await LocationsService.get.locations()
      this.locations = locations
          .map(location => ({...location, selected: !!location.active}))
          .sort(this.sortLocationCompareFunc);
      this.displayedLocations = this.locations;
      this.locationsLoading = false;
    },
    sortLocationCompareFunc: (a, b) => {
      if (a.selected > b.selected) return -1;
      if (a.selected < b.selected) return 1;
      return 0;
    },
    selectLocation(location) {
      location.selected = !location.selected;
      this.locations.sort(this.sortLocationCompareFunc);
      this.displayedLocations.sort(this.sortLocationCompareFunc);
    },
    async setupLocations() {
      try {
        this.loading = true;
        const selectedLocationIds = this.selectedLocations.map(location => location.id);

        const {
          data: {
            estimated_time_mins,
            steps
          }
        } = await LocationsService.post.saveLocations(selectedLocationIds);

        this.loading = false;
        this.showSuccessToast('Locations setup successfully!');
        this.$bvModal.show('setup-steps-locations-modal')
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.showErrorToast('Something went wrong.');
      }
    },
    selectedAllLocations() {
      // Set selected attribute to all
      this.locations = this.locations.map(location => ({
        ...location,
        selected: !this.allLocationsAreSelected
      }));
      this.displayedLocations = this.locations
      this.displayedLocations.sort(this.sortLocationCompareFunc);


    }
  },
  mounted() {

    this.displayedLocations = this.locations

    this.fetchLocations()

  }
}
</script>
